import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FdsChevron } from '../chevron/fds-chevron';

import './dropdown.scss';
import { InfoIconToolTip } from '../info-icon-tooltip/info-icon-tooltip';
import warning from '../../../assets/warning.png';
import { KEYBOARD_KEYS } from '../../../constants';

export interface Option {
    label: string;
    value: string;
}

interface Props {
    value: any;
    label: string;
    options: string[] | Option[];
    errorMessage?: string;
    errorLabel?: string;
    className?: string;
    onChange: (label: string, index: number, value?: string) => any;
    onClick?: () => void;
    disabled?: boolean;
    noLabel?: boolean;
    showLabel?: boolean;
    tabIndex?: number;
    id?: string;
    onDropdownClick?: () => void;
    handelBlur?: (e?: any) => void;
    osbInfoTooltip?: boolean;
    osbTooltipContent?: string;
    ariaLabel?: string;
    dataTestId?: string;
}

const DEFAULT_CURRENT_INDEX = 0;

const Dropdown = (props: Props) => {
    const buttonElement = useRef<HTMLButtonElement>(null);
    const panelElement = useRef<HTMLUListElement>(null);
    const itemElements: HTMLLIElement[] = [];
    const [selectedItemAriaLabel, setSelectedItemAriaLabel] = useState<string>(
        props.ariaLabel || `${props.label} Dropdown Menu`
    );

    const [keySelectionIndex, setKeySelectionIndex] = useState<number>(
        DEFAULT_CURRENT_INDEX
    );

    const [accessibilityActive, setAccessibilityActive] = useState<boolean>(
        false
    );

    const [currentIndex, setCurrentIndex] = useState<number>(
        DEFAULT_CURRENT_INDEX
    );
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const options =
        props.options.length && props.options[0] instanceof Object
            ? (props.options as Option[]).map((opt: Option) => opt.label)
            : Array.from(props.options as string[]);
    if (
        props.options.length < 1 ||
        !(props.noLabel === undefined ? true : props.noLabel)
    )
        options.unshift(props.label);

    const addItemRef = (element: HTMLLIElement) => {
        itemElements.push(element);
    };

    const getSelectedItemElement = (index: number) => {
        return itemElements.find(element =>
            element.classList.contains('dropdown-item-' + index)
        );
    };

    const alignPanelToItem = (index: number) => {
        const panel = panelElement.current;
        const selectedItem = getSelectedItemElement(index);
        if (!!panel && !!selectedItem) {
            const scrollBottom = panel.clientHeight + panel.scrollTop;
            const elementBottom =
                selectedItem.offsetTop + selectedItem.offsetHeight;

            if (elementBottom > scrollBottom) {
                panel.scrollTop = elementBottom - panel.clientHeight;
            } else if (selectedItem.offsetTop < panel.scrollTop) {
                panel.scrollTop = selectedItem.offsetTop;
            }
        }
    };

    const setSelectedItem = (index: number) => {
        alignPanelToItem(index);

        setCurrentIndex(index);
        const selectedItem = getSelectedItemElement(index);
        if (selectedItem) {
            setKeySelectionIndex(index);
            selectedItem.focus();
        }
        props.options.length && props.options[0] instanceof Object
            ? props.onChange(
                  options[index],
                  index,
                  (props.options as Option[])?.find(
                      option => option.label === options[index]
                  )?.value
              )
            : props.onChange(options[index], index);
    };

    const handleDropdownClick = (e: any) => {
        e.stopPropagation();
        if (!isOpen) {
            props.onDropdownClick?.();
            setIsOpen(true);
        } else {
            setIsOpen(false);
            setSelectedItem(currentIndex);
        }
    };

    const closeDropdown = (focusButton: boolean) => {
        setIsOpen(false);
        if (focusButton && buttonElement.current) {
            buttonElement.current.focus();
            setSelectedItem(currentIndex);
        }
    };

    const closeDropdownWithSelectedItem = (focusDiv: boolean) => {
        setIsOpen(false);
        setSelectedItemAriaLabel(options[keySelectionIndex]);
        if (focusDiv && buttonElement.current) {
            buttonElement.current.focus();
        }
    };

    const closeDropdownOnBlur = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleKeyDownList = (
        event: React.KeyboardEvent<HTMLUListElement>
    ) => {
        event.preventDefault();
        event.stopPropagation();
        setAccessibilityActive(true);
        switch (event.key) {
            case 'ArrowUp':
                if (keySelectionIndex !== undefined && keySelectionIndex > 0) {
                    alignPanelToItem(keySelectionIndex - 1);
                    setKeySelectionIndex(keySelectionIndex - 1);
                } else {
                    alignPanelToItem(options.length - 1);
                    setKeySelectionIndex(options.length - 1);
                }
                break;
            case 'ArrowDown':
                if (
                    keySelectionIndex !== undefined &&
                    keySelectionIndex < options.length - 1
                ) {
                    alignPanelToItem(keySelectionIndex + 1);
                    setKeySelectionIndex(keySelectionIndex + 1);
                } else {
                    alignPanelToItem(0);
                    setKeySelectionIndex(0);
                }
                break;
            case 'Enter':
                setSelectedItem(keySelectionIndex);
                setIsOpen(false);
                setAccessibilityActive(false);
                closeDropdownWithSelectedItem(true);
                break;
            case 'Home':
                setSelectedItem(0);
                break;
            case 'End':
                setSelectedItem(options.length - 1);
                break;
            case 'Escape':
                closeDropdown(true);
                break;
        }
    };

    const handleKeyDownButton = (
        event: React.KeyboardEvent<HTMLButtonElement>
    ) => {
        if (
            !isOpen &&
            (event.key === KEYBOARD_KEYS.ARROW_UP ||
                event.key === KEYBOARD_KEYS.ARROW_DOWN)
        ) {
            event.preventDefault();
            event.stopPropagation();
            handleDropdownClick(event);
        }
        if (event.key === KEYBOARD_KEYS.TAB) {
            props.handelBlur && props.handelBlur(event);
        }
    };

    const isSelectedItem = (index: number) => {
        return (
            (keySelectionIndex === index && accessibilityActive) ||
            (currentIndex === index && !accessibilityActive)
        );
    };
    const sanitizedOptionId = (optionValue: string, optionIndex: number) => {
        if (
            props?.options &&
            props.options.length > 0 &&
            props.options[optionIndex] &&
            Object.prototype.hasOwnProperty.call(
                props.options[optionIndex],
                'value'
            ) &&
            (props.options[optionIndex] as Option).value !== ''
        ) {
            return (
                optionIndex +
                '_' +
                (props.options[optionIndex] as Option).value.replace(/ /g, '-')
            );
        }

        if (!optionValue) {
            return optionIndex + '_';
        }

        return optionIndex + '_' + optionValue.replace(/ /g, '-');
    };

    useEffect(() => {
        if (isOpen && !!panelElement.current) {
            panelElement.current.focus();
        }
    }, [isOpen]);

    useEffect(() => {
        let newIndex = DEFAULT_CURRENT_INDEX;
        const currentIndexOfValue = options.indexOf(props.value);
        if (currentIndexOfValue > 0) newIndex = currentIndexOfValue;

        setCurrentIndex(newIndex);
    }, [props.value, options]);

    useEffect(() => {
        window.addEventListener('click', closeDropdownOnBlur);
        return () => {
            window.removeEventListener('click', closeDropdownOnBlur);
        };
    }, [closeDropdownOnBlur]);

    const getSelectedValue = () => {
        return props.value || (isOpen ? '' : props.label);
    };

    const setSelectedItemFocusOnHover = (index: number) => {
        setAccessibilityActive(true);
        setKeySelectionIndex(index);
        alignPanelToItem(index);
    };
    const handlerBlurButton = (e: any) => {
        props.handelBlur?.(e);
    };

    const isShowLabel = props.showLabel === undefined ? true : props.showLabel;

    return (
        <div className="dropdown-container-wrapper">
            {(isOpen || props.value) && props.label && isShowLabel ? (
                <label className="label">{props.label}</label>
            ) : (
                <div />
            )}

            <span
                className={`dropdown ${props.className} ${
                    isOpen ? 'dropdown-open' : ''
                }`}
                onClick={props.onClick}
            >
                <div
                    className={`error-message dropdown-error-message${
                        props.errorMessage && !isOpen ? '-show' : '-hide'
                    }`}
                    id={props.errorLabel}
                >
                    {props.errorMessage}
                    <img src={warning} alt="" className="error-icon" />
                </div>
                <button
                    id={props.id}
                    data-testid={props.dataTestId}
                    className={`dropdown-button`}
                    onClick={handleDropdownClick}
                    disabled={props.disabled || props.options.length === 0}
                    ref={buttonElement}
                    onKeyDown={handleKeyDownButton}
                    onBlur={handlerBlurButton}
                    aria-label={selectedItemAriaLabel}
                    tabIndex={0}
                    type="button"
                    aria-describedby={props.errorLabel}
                    aria-expanded={isOpen}
                >
                    <div className="dropdown-current-item">
                        <span
                            dangerouslySetInnerHTML={{
                                __html: getSelectedValue(),
                            }}
                        />

                        <FdsChevron
                            direction={isOpen ? 'up' : 'down'}
                            type="unfilled"
                        />
                    </div>
                </button>
                {props.osbInfoTooltip && (
                    <InfoIconToolTip
                        tooltipInfoIconClass={'dark'}
                        tooltipContent={props.osbTooltipContent || ''}
                    />
                )}
                {isOpen && (
                    <div className="dropdown-container">
                        <ul
                            className={`dropdown-items-panel items-panel-open`}
                            tabIndex={0}
                            role="listbox"
                            aria-activedescendant={sanitizedOptionId(
                                options[keySelectionIndex],
                                keySelectionIndex
                            )}
                            ref={panelElement}
                            onKeyDown={handleKeyDownList}
                            onBlur={() => setIsOpen(false)}
                        >
                            {options?.map((option, index) => (
                                <li
                                    data-testid={`Select ${option}`}
                                    id={sanitizedOptionId(option, index)}
                                    key={`${option?.toLowerCase()}-${index}`}
                                    className={`dropdown-item dropdown-item-${index} ${
                                        isSelectedItem(index)
                                            ? 'selected-item'
                                            : ''
                                    }`}
                                    onClick={e => {
                                        e.stopPropagation();
                                        setSelectedItem(index);
                                        closeDropdown(false);
                                    }}
                                    role="option"
                                    ref={addItemRef}
                                    value={index}
                                    onMouseEnter={() => {
                                        setSelectedItemFocusOnHover(index);
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: option,
                                    }}
                                ></li>
                            ))}
                        </ul>
                    </div>
                )}
            </span>
        </div>
    );
};

export default Dropdown;
